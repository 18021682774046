<template>
  <div
    ref="card"
    class="tickets-table">
    <v-data-table
      class="elevation-1 table-chat"
      :headers="headers"
      :items="tickets"
      hide-default-footer>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ dueAt(item.dueAt) }}</td>
          <td class="text-center">
            {{ $n(item.originalValue, 'currency') }}
          </td>
          <td
            v-for="(detail, index) in item.details"
            :key="index"
            class="text-center">
            {{ $n(detail.value, 'currency') }}
          </td>
          <td class="text-center">
            {{ $n(item.currentValue, 'currency') }}
          </td>
        </tr>
        <tr v-if="tickets.indexOf(item) === tickets.length - 1">
          <td class="text-center">
            <strong>Total</strong>
          </td>
          <td class="text-center">
            <strong>{{ $n(proposal.originalValue, 'currency') }}</strong>
          </td>
          <td
            v-for="(detail, index) in proposal.details"
            :key="index"
            class="text-center">
            {{ $n(detail.value, 'currency') }}
          </td>
          <td class="text-center">
            <strong>{{ $n(proposal.currentValue, 'currency') }}</strong>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    props: {
      tickets: {
        type: Array,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        headers: [
          { text: 'Vencimento', value: 'date', align: 'center', sortable: false },
          { text: 'Valor Original', value: 'original', align: 'center', sortable: false },
          { text: 'Correção monetária', value: 'correction_index', align: 'center', sortable: false },
          { text: 'Multa', value: 'mulfineta', align: 'center, sortable: false' },
          { text: 'Juros', value: 'interest_rate', align: 'center', sortable: false },
          { text: 'Honorários', value: 'fee', align: 'center', sortable: false },
          { text: 'Total', value: 'currentValue', align: 'center', sortable: false }
        ],
        updateTickets: [],
        showAllTickets: null
      }
    },
    mounted () {
      this.scaleAnimation()
    },
    methods: {
      dueAt (date) {
        date = new Date(date)
        return new Date(date.getTime() + date.getTimezoneOffset() * 60000).toLocaleDateString('pt-BR')
      },
      scaleAnimation () {
        const card = this.$refs.card

        this.anime({
          targets: card,
          easing: 'easeInOutQuad',
          delay: 800,
          scale: [
            { value: 0, duration: 100 },
            { value: 1, duration: 500 }
          ],
          opacity: [
            { value: 0, duration: 100 },
            { value: 1, duration: 200 }
          ]
        })
      }
    }
  }
</script>

<style lang="sass">
  .tickets-table
    opacity: 0
    transform: scale(0)
</style>
