<template>
  <v-container class="pa-0">
    <div class="cb-message resume-proposal d-flex mb-2">
      <span
        ref="icon"
        class="cb-message-icon">
        <avatar :alias="alias" />
      </span>
      <div
        ref="text"
        class="cb-message-text d-flex flex-column">
        <span class="text--primary body-2">
          <span class="font-weight-bold">{{ name }}</span>
          {{ timeHour }}
        </span>
        <span class="letters">Veja a proposta abaixo:</span>
      </div>

      <modal
        :show="showModal"
        max-width="900px"
        hide-actions
        @close="closeModal">
        <div slot="title">
          Detalhes do Cálculo
        </div>
        <div slot="content">
          <tickets-table
            :tickets="proposal.tickets"
            :proposal="proposal" />
        </div>
      </modal>
    </div>
    <div class="my-1 proposal-card">
      <v-layout>
        <v-flex
          xs12
          md6>
          <b>Valor Original</b>
        </v-flex>
        <v-flex
          xs12
          md6
          class="text-right">
          {{ $n(parcelSelected.originalValue, 'currency') }}
        </v-flex>
      </v-layout>
      <v-divider class="my-1" />
      <v-layout>
        <v-flex
          xs12
          md8>
          <b>Acréscimos</b>
          <v-tooltip
            right
            allow-overflow
            content-class="table_trasparent">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                v-on="on">
                <v-icon small class="primary--text">
                  mdi-alert-circle
                </v-icon>
              </v-btn>
            </template>
            <v-simple-table dense>
              <tbody>
                <tr
                  v-for="(item, index) in proposal.details"
                  :key="index">
                  <td>{{ $t('calculateParams.' + item.type) }}</td>
                  <td>{{ $n(item.value, 'currency') }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-tooltip>
        </v-flex>
        <v-flex
          xs12
          md4
          class="text-right">
          <span>{{ $n(addition, 'currency') }}</span>
        </v-flex>
      </v-layout>
      <v-divider class="my-1" />

      <v-layout>
        <v-flex
          xs12
          md6
          class="">
          <b>Valor corrigido</b>
          <v-btn text x-small color="primary" @click="openModal">
            detalhes
          </v-btn>
        </v-flex>
        <v-flex
          xs12
          md6
          class="text-right">
          <span>{{ $n(proposal.currentValue, 'currency') }}</span>
        </v-flex>
      </v-layout>
      <v-divider class="my-1" />

      <v-layout>
        <v-flex
          xs12
          md6
          class="">
          <b class="success--text">Descontos</b>
          <v-tooltip
            right
            allow-overflow
            content-class="table_trasparent">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                v-on="on">
                <v-icon small class="primary--text">
                  mdi-alert-circle
                </v-icon>
              </v-btn>
            </template>
            <v-simple-table dense>
              <tbody>
                <tr
                  v-for="(value, key, index) in discountObj"
                  :key="index">
                  <td>{{ $t('calculateParams.' + key) }}</td>
                  <td>{{ $n(value, 'currency') }}</td>
                </tr>
                <tr
                  v-if="discountOriginal > 0">
                  <td>Desconto</td>
                  <td>{{ $n(discountOriginal, 'currency') }}</td>
                </tr>
                <tr
                  v-if="allowance > 0">
                  <td>Dispensa de Multa</td>
                  <td>{{ $n(allowance, 'currency') }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-tooltip>
        </v-flex>
        <v-flex
          xs12
          md6
          class="text-right">
          <span class="success--text">{{ $n(discount, 'currency') }}</span>
        </v-flex>
      </v-layout>
      <v-divider class="my-1" />
      <v-layout>
        <v-flex
          xs12
          md6
          class="">
          <b>Valor da proposta</b>
        </v-flex>
        <v-flex
          xs12
          md6
          class="text-right">
          <b>{{ $n(parcelSelected.currentValue, 'currency') }}</b>
        </v-flex>
      </v-layout>
      <v-divider v-if="proposal.parcel > 1" class="my-1" />
      <v-layout v-if="proposal.parcel > 1">
        <v-flex
          xs12
          md12
          class="">
          <b>Entrada {{ $n(parcelSelected.installments[0].value, 'currency') }} + {{ parcelSelected.parcel - 1 }}x {{ $n(parcelSelected.installments[1].value, 'currency') }}</b>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
  import chatUiMixin from '@/mixins/chat-ui'
  import Modal from './Modal'
  import Avatar from './Avatar'
  import TicketsTable from './TicketsTable'

  export default {
    components: { Avatar, Modal, TicketsTable },
    mixins: [ chatUiMixin ],
    data () {
      return {
        showAdditionLabel: true,
        showModal: false
      }
    },
    computed: {
      data () {
        return this.record.data
      },
      proposal () {
        return this.data.options.proposal
      },
      company () {
        return this.proposal.company
      },
      parcelSelected () {
        return this.proposal.installmentSelected
      },
      discount () {
        return this.proposal.currentValue - this.proposal.installmentSelected.currentValue
      },
      allowance () {
        return this.parcelSelected.tickets.filter(t => t.ignoreCalculate).mapBy('discount').reduce((acc, value) => acc + value, 0)
      },
      discountObj () {
        const proposalAditional = this.proposal.details.reduce((acc, item) => { return {...acc, [item.type]: item.value } }, {})
        return this.parcelSelected.details.reduce((acc, item) => { return {...acc, [item.type]: proposalAditional[item.type] - item.value } }, {})
      },
      discountOriginal () {
        return this.parcelSelected.tickets.filter(t => !t.ignoreCalculate).mapBy('discount').reduce((acc, value) => acc + value, 0)
      },
      setLabelParcel () {
        return this.data.options.parcel === 1 ? 'Proposta à vista' : 'Proposta parcelada em'
      },
      addition () {
        return this.proposal.details.reduce((total, item) => total + item.value, 0)
      },
      timeHour () {
        return this.record.time
      }
    },
    mounted () {
      this.iconAnimation()
      this.messageAnimation()
    },
    methods: {
      openModal () {
        this.showModal = true
      },
      closeModal () {
        this.showModal = false
      }
    }
  }
</script>

<style lang="sass">
  .table_trasparent
    padding: 5px 0
    .theme--light.v-data-table
      background: transparent
      color: #fff

  .resume-proposal
    // display: flex
    // align-items: flex-start

    .cb-message-icon
      width: 34px
    .cb-message-text
      width: 100%

    .v-card
      color: #000

      .layout
        margin: 3px

      .details:last-child
        border-bottom: 1px solid $gray

  .proposal-card
    margin: 0 auto
    font-size: 12px
    background: #fafafa
    padding: 10px
    border-radius: 10px

  @media screen and (max-width: $max-mobile)
    .resume-proposal
      flex-flow: row wrap
      .cb-message-text
        width: 320px
      .proposal-card
        width: 100%
        margin-left: 0
        margin-top: 10px !important
        font-size: 14px
</style>
