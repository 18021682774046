<template>
  <v-avatar color="primary" class="white--text avatar" size="34px" tile>
    <img v-if="isImg" :src="obj.photoURL" :alt="obj.name">
    <span v-else>{{ alias }}</span>
  </v-avatar>
</template>

<script>
  export default {
    name: 'Avatar',
    props: {
      isImg: {
        type: Boolean,
        default: false
      },
      alias: {
        type: String,
        default: ''
      },
      obj: {
        type: [Object, String],
        default: () => ({})
      },
      defaultImg: {
        type: String,
        default: 'mm'
      }
    }
  }
</script>

<style lang="sass">
  .avatar
    border-radius: 5px
</style>
